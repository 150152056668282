$primary: #f6d000;
$primary-light:#FFFCEB;
$second-primary:#002F8D;
$second-primary-light:#e6e9f5;
$black:#131313;
$second-accent:#333333;
$white: #fff;
$borderX: 20px;


.promo{
    max-height: 50vh;

    position: relative;
    z-index: 20;
    .russkiyVoeniyKorablIdiNahuy-card{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #003ABD;
        border-radius: $borderX;
        margin: 15px 15px 0px 15px;
        height: 100px;
        div{
            &:first-child{
                display: flex;
                justify-content: start;
                align-items: center;
            }
        }
        .promo-image{
            padding: 20px;
            justify-self: flex-start;
            img{
                width: 40px
            }
        }
        .promo-text{
            border-left: solid 1px white;
            p{
                margin: 0 0 0 20px !important;
                max-width: 80%;
                font-size: 28px;
                color: #fff;
                font-weight: 200;
            }
            span{
                font-weight: 800;
            }
        }
        .promo-button{
            padding-right: 20px;
            Button{
                font-weight: 700;
                font-size: 22px;
                line-height: 137%;
                letter-spacing: -0.01em;
                padding: 14px 48px;
                background: $primary;
                border-radius: 10px;
                color: #131313;
                transition: all .35s linear;
                &:hover{
                    background: #FFF099
                }
            }
        }

    }

}
.blackBorder{
    padding: 15px;
}
.home {
    border-radius: $borderX;
    max-height: 100vh;
    background-color: $primary;
    background-size: cover;
    padding: 0 4vw;
    z-index: 1;


}

.header{
    z-index: 10;
    position:relative;
    display: flex;
    justify-content: space-between;
    vertical-align: center;
    padding: 25px 0;
    .logo{
        height: calc(35px + 1.4vw);
    }
    .menu{
        align-self: center;
        img{
            height: calc(20px + 1.3vw);
        }
    }
}

.main{
    display: flex;
    z-index: 10;
    position:relative;
}
.parallaxBannerFix{
    position: initial !important;
}

.main-content{
    flex: 2 1;
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    align-items: flex-start;
    h1{
        margin: 0;
    }
    p{
        margin-top: 2px;
        font-weight: 200;
    }
    .links-main{
        border-radius: 25px;
        background-color: #fff;
        padding: 0 25px;
        margin-top: 10px;
        .links-main-telegram{
            display: inline;
        }
        img{
            height: calc(15px + 1.1vw);
            margin: 12px;
            padding-top: 5px;
            &:hover{
                opacity: 0.9;
            }
        }
    }
}


.main-img{
    flex: 1 1;
    img{
        z-index: 10;
    }
}
.videSection{
    flex: 1 1;
    margin-top: -30px;
    .video-card{
        background-color: #F6D000;
        margin: 0px 70px 60px 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;

        .video{
            padding: 100px;
            max-height: 100vh;
            height: 70vh;
            width: 70%;


        }
    }
}
.descriptionSection{
    background-color: $white;
    display: flex;
    justify-content: space-between;
    padding: 4vw;
    align-items: center;
    flex-wrap: wrap;
        .yellow{
            flex: 1;
            position: relative;
            z-index: 20;
            margin: 10vh 0 10vh 0;
            background: #F6D000;
            -webkit-box-shadow: -17px -61px 51px -24px rgba(34, 60, 80, 0.13);
            -moz-box-shadow: -17px -61px 51px -24px rgba(34, 60, 80, 0.13);
            box-shadow: -17px -61px 51px -24px rgba(34, 60, 80, 0.13);
            padding: 56px;
            border-radius: 16px;
            width: calc(39% - 16px);
            position: relative;
            .section-title{
                margin: 0 0 16px;
                letter-spacing: -0.03em;
                line-height: 113.5%;
                font-style: normal;
                font-size: 60px;
                font-weight: 900;
            }
            .block-description{
                font-weight: 300;
                font-size: 32px;
                line-height: 137%;
                letter-spacing: -0.01em;
                margin: 0 0 66px;
                color: #131313
            }
            .abstraction{
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 0
            }
            .get-beta{
                font-weight: 700;
                font-size: 22px;
                line-height: 137%;
                letter-spacing: -0.01em;
                padding: 14px 48px;
                background: #FFF9D6;
                border-radius: 8px;
                color: #131313;
                transition: all .35s linear;
                &:hover{
                    background: #FFF099
                }
            }
        }
    .white{
        flex: 1.5;
        h3{ font-size: 40px;
        }
        p{
            max-width: 90%;
            font-weight: 200;
        }
    }
}
.food-app{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 20;
    .food-app-el-right{
        z-index: 20;
        position: relative;
    }
    .food-app-el{
        flex:1 1 350px;
        padding-bottom: 4vh;
        padding-top: 4vh;
        background-color: #fff;
        border-radius: $borderX;


        &:first-child{
            overflow-x: hidden;
            background-color: $primary;
            background-image: url("/assets/background-waves.png");
            display: flex;
            flex-direction: column;
            .flex-left{
                background-color: #fff;
                width: 80%;
                align-self: flex-start;
                border-radius: 0 20px 20px 0;
                margin: 10vh 0 10vh 0;
                padding: 15px 40px 15px 40px;
                display: flex;
                .text-place{
                    font-weight: 200;
                    align-items: center;

                }
                .number-place{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p{
                        font-size: 50px;
                        font-weight: 200;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
            .flex-right{
                background-color: #232323;
                width: 80%;
                align-self: flex-end;
                border-radius: 20px 0px 0px 20px;
                padding: 15px 40px 15px 40px;
                display: flex;
                .text-place{
                    align-items: center;
                    padding-left: 20px;
                    h3{
                        color: $white;
                    }
                    p{
                        color: $white;
                        font-weight: 200;
                    }
                }
                .number-place{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p{
                        font-size: 50px;
                        color: $white;
                        font-weight: 200;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
    h3{
        margin: 0;
    }
}

.earningSection{
    background-color: #fff;
    .cardModule{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .card{
            background-color: $primary;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: calc(23.3% - 10px);
            height: 247px;
            margin: 30px;
            cursor: pointer;
            h3,p{
                color: #131313
            }
            h3{
                margin: 10px 10px;
            }
           p{
               margin: 10px 20px;
               font-size: 15px;
               color: black;
               font-weight: 300;

           }
            img{
                width: 90%;
            }
        }
    }
}


.faq{
    display: flex;
    flex-wrap: wrap;
    padding: 0 4vw;
    .faq-el{
        flex:1 1 350px;
        padding:4vw;
       img{
           width: 100%;
       }
    }
}

.contact{
    background-color: #EEEEEE;
    padding: 20px 4vw;
    border-radius: 20px 20px 0 0;
    .center{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
        position: relative;
        z-index: 20;
    }
    .links-contact{
        display: flex;
        justify-content: space-between;
        img{
            background-color: $primary;
            height: calc(15px + 1.5vw);
            padding: 18px;
            border-radius: 15px;
            &:hover{
                opacity: 0.9;
            }
        }
    }
    .contactForm{
        background-color: #fff;
        border-radius: $borderX;
        padding: 20px;
        margin: 30px 0;
        display: flex;
        justify-content: space-between;
        text-align: left;
        .contactImg{
            height: auto;
            width: 50%;
            object-fit: contain;
        }
        form{
            width: 50%;
            input{
                width: 100%;
            }
            button{
                width: 100%;
            }
        }
    }
}

footer{
    background-color: $primary;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 4vw;
    border-radius: 0 0 20px 20px;
    img{
        height: 50px;
    }
    .footer-info{
        flex: 1 1;
        a{
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;

        }
    }
    .footer-links{
        margin-top: 15px;
        flex: 1 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        li{

            a{
                text-decoration: none;
                font-size: 12px;
                font-weight: 500;
                display: table;
                margin-bottom: 10px;

            }
        }
    }
    .divider{
        border-top: solid 1px black;
        display: inline-block;
        p{
            font-size: 14px;
        }

    }
}

input{
    padding: 10px 8px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    background-color: #F6F6F6;
}

button{
    background-color: $primary;
    padding: 14px 48px;
    border-radius: 8px;
    border: none;
    font-size: calc(10px + 0.5vw);
    cursor: pointer;
    &:hover{
        opacity: 0.9;
    }
}
.image-mock{
    text-align: center;
    img{
       max-height: 800px;
    }
}

///////////////////////////

.Collapsible{
    margin-bottom: 10px;
}

.Collapsible__contentInner {
    padding: 10px;
    border-top: 0;
    border-radius: 10px;
    background: $primary-light;
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
}

.Collapsible__trigger {
    display: block;
    font-weight: 600;
    font-size: calc(12px + 0.8vw);
    text-decoration: none;
    position: relative;
    padding: 20px;
    background: #F6F6F6;
    border-radius: 10px;
    transition: all .35s linear;
    cursor: pointer;
    &:after {
      content: '\276E';
      transform: rotate(-90deg);
      position: absolute;
      right: 20px;
      top: 20px;
      display: block;
      transition: transform 300ms;
    }
    &.is-open {
        &:after {
          transform: rotateZ(90deg);
        }
    }
    &.is-disabled {
        opacity: 0.5;
        background-color: grey;
    }
    &:hover{
        background: $primary;
    }
  }
.our-team {
    padding: 92px 0
}

.our-team .section-wrapper .section-title {
    margin: 0 0 56px
}

.our-team .section-wrapper .team-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}

.our-team .section-wrapper .team-list .item {
    width: calc(25% - 25px)
}

.our-team .section-wrapper .team-list .item .image {
    position: relative;
    margin: 0 0 32px;
    transition: all .35s linear;
    border-radius: 20px;
    max-height: 330px;
    background: #F6D000
}

.our-team .section-wrapper .team-list .item .image .team-member {
    border-radius: 16px;
    max-height: 338px;
    width: 100%;
    object-fit: cover;
    transition: all .35s linear;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%)
}

.our-team .section-wrapper .team-list .item .image .icon {
    position: absolute;
    top: 26px;
    left: 26px;
    opacity: 0;
    visibility: hidden;
    transition: all .35s linear
}

.our-team .section-wrapper .team-list .item .image:hover {
    box-shadow: 0 4px 8px rgba(246, 208, 0, 0.15), 0 8px 48px rgba(246, 208, 0, 0.25);
    background: #F8F8F8
}

.our-team .section-wrapper .team-list .item .image:hover .icon {
    opacity: 1;
    visibility: visible
}

.our-team .section-wrapper .team-list .item .image:hover .team-member {
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
}

.our-team .section-wrapper .team-list .item .name {
    font-weight: bold;
    font-size: 32px;
    line-height: 137%;
    letter-spacing: -0.01em;
    margin: 0 0 8px;
    color: #131313
}

.our-team .section-wrapper .team-list .item .position {
    font-weight: 300;
    font-size: 22px;
    line-height: 137%;
    letter-spacing: -0.01em;
    color: #131313
}

.our-team .section-wrapper .team-list .item.another .image {
    background: #F6D000;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 330px
}

.our-team .section-wrapper .team-list .item.another .image .count {
    font-weight: 900;
    font-size: 80px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #FFF9D6
}


  ///////////////////////////////

  @media screen and (min-width:768px){
    .center{
        width: 50%;
    }
}

@media screen and (max-width:768px){
    .main-img,.phone-un{
        display: none;
    }
}
@media screen and (max-width:768px){
    .russkiyVoeniyKorablIdiNahuy-card{
        height: 200px!important;
        flex-direction: column;
        margin: 0 !important;
        border-radius: 0 !important;
        .promo-text{
            p{
                font-size: calc(13px + 0.9vw)!important;
            }
        }
        .promo-button{
            padding-right: 0!important;
            padding-bottom: 20px;
        }
    }
}
@media screen and (max-width:768px) {
    .blackBorder{
        padding: 0;
    }
    .main{
        padding: 10px;
        .main-content{
            height: initial;
            .links-main{
                padding: 0;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    footer{
        display: inherit !important;
        .divider{
            display: block ;
        }
    }
}
@media screen and (max-width: 768px){
    .video-header{
        display: none;
    }
    .video{
        height: 30vh !important;
        width: 100% !important;
        padding: 30px !important;
    }
    .video-card{
        width: 100%;
        margin-left: 0!important;
        margin-right: 0!important;
    }
    .page-home .our-team {
        padding: 42px 0
    }

     .our-team .section-wrapper .section-title {
        margin: 0 0 32px
    }

     .our-team .section-wrapper .team-list {
        flex-wrap: wrap
    }

     .our-team .section-wrapper .team-list .item {
        width: calc(50% - 8px);
        margin: 0 0 32px
    }

     .our-team .section-wrapper .team-list .item .image {
        position: relative;
        margin: 0 0 32px;
        transition: all .35s linear;
        border-radius: 16px;
        max-height: 330px
    }

     .our-team .section-wrapper .team-list .item .image .team-member {
        border-radius: 16px
    }

     .our-team .section-wrapper .team-list .item .name {
        font-weight: 700;
        font-size: 18px;
        line-height: 137%
    }

     .our-team .section-wrapper .team-list .item .position {
        font-weight: 400;
        font-size: 16px;
        line-height: 137%
    }

     .our-team .section-wrapper .team-list .item.another .image {
        background: #F6D000;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 166px
    }

     .our-team .section-wrapper .team-list .item.another .image .count {
        font-weight: 900;
        font-size: 80px;
        line-height: 110%;
        letter-spacing: -0.03em;
        color: #FFF9D6
    }

}
