body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  letter-spacing: -0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-color: #131313;
  background-color: #fff;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: calc(24px + 4vw);
}

h2{
  font-size: calc(16px + 1.8vw);
}

h3{
  font-size: calc(14px + 1.2vw);
}

p{
  font-size: calc(13px + 0.2vw);
}

a{
  color:#000
}
